<template lang="html">
    <div class="card card-primary card-outline">
        <div class="card-header">
            <h3 class="card-title">我的资料</h3>
            <div class="card-tools mr-0">
            </div>
        </div>

        <!-- /.box-header -->
        <div class="card-body p-0 p-sm-4">
            <div v-if="loading" style="flex:auto;display:flex;text-align:center;justify-content:center">
                <div>
                    <i class="fas fa-spinner fa-pulse"></i>
                    <p>
                        载入中…
                    </p>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-md-6">
                    <!-- Profile Image -->
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <div class="text-center">
                                <router-link :to="{ name: 'Avatar'}">
                                    <img class="profile-user-img img-fluid img-circle" :src="user.avatarUrl" alt="User profile picture">
                                </router-link>
                            </div>

                            <h3 class="profile-username text-center">{{user.nickName}}</h3>

                            <p class="text-muted text-center">{{user.userName}}</p>

                            <ul class="list-group list-group-unbordered mb-3">
                                <li class="list-group-item">
                                    <b>邮箱：</b>{{user.email}} <span class="float-right"><span v-if="user.email" class="ml-1">{{user.emailConfirmed?'已验证':'未验证'}}</span><router-link :to="{ name: 'ProfileEdit'}" v-else>未设置</router-link> </span>
                                </li>
                                <li class="list-group-item">
                                    <b>手机：</b>{{user.phoneNumber}} <span class="float-right"><span v-if="user.phoneNumber" class="ml-1">{{user.phoneNumberConfirmed?'已验证':'未验证'}}</span><router-link :to="{ name: 'ProfileEdit'}" v-else>未设置</router-link> </span>
                                </li>
                                <li class="list-group-item">
                                    <b>公众号：</b><a class="float-right">{{user.wxSubscribe?'已关注':'未关注'}} </a>
                                </li>
                            </ul>

                            <router-link :to="{ name: 'ProfileEdit'}" class="btn btn-primary btn-block"><b>修改</b></router-link>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
                <div class="col-md-6">
                    <!-- About Me Box -->
                    <div class="card card-primary">
                        <div class="card-header">
                            <!--<h3 class="card-title">关于我</h3>-->
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <strong><i class="fas fa-book mr-1"></i> 简介</strong>

                            <div class="text-muted" v-html="user.summary">
                            </div>

                            <!--<hr>

                            <strong><i class="fas fa-map-marker-alt mr-1"></i> Location</strong>

                            <p class="text-muted">Malibu, California</p>

                            <hr>

                            <strong><i class="fas fa-pencil-alt mr-1"></i> Skills</strong>

                            <p class="text-muted">
                                <span class="tag tag-danger">UI Design</span>
                                <span class="tag tag-success">Coding</span>
                                <span class="tag tag-info">Javascript</span>
                                <span class="tag tag-warning">PHP</span>
                                <span class="tag tag-primary">Node.js</span>
                            </p>

                            <hr>

                            <strong><i class="far fa-file-alt mr-1"></i> Notes</strong>

                            <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam fermentum enim neque.</p>-->
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>

        <!-- /.box-body -->


    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
                user: {},
                loading: false
            }
        },
        created() {
            this.loadData()
        },
        methods: {
            loadData() {
                var url = "/Api/My/Users"
                this.loading = true
                this.$axios.get(url)
                    .then((response) => {
                        console.log(response)
                        this.user = response.data
                        this.loading = false
                    }).catch((error) => {
                        console.log(error)
                        this.loading = false
                    })

            },

        },
    }
</script>

<style lang="css" scoped>
</style>
